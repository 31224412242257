<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
      @search="getExtrato"
      @filter="getExtrato"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data Competência"
          name="arrayData"
          v-model="formFiltros.arrayDataExtrato"
          multiple
          clearable
        />
      </template>
    </ed-table>
  </div>
</template>

<script>
import { EdTable, EdInputDate } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdTable,
    EdInputDate,
  },
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  watch: {},
  mounted() {
    this.getExtrato();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      boolCarregado: false,
      arrayHeaders: [
        {
          text: "Data",
          sortable: true,
          value: "strData",
        },
        {
          text: "Descrição",
          sortable: true,
          value: "strDescricao",
        },
        {
          text: "Tipo Transação",
          sortable: true,
          value: "strTipo",
        },
        {
          text: "Valor",
          sortable: true,
          value: "floatValor",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros: {
        boolListarExtrato: true,
        intContaBancariaId: this.formData.intId,
        arrayDataExtrato: [
          this.$utilidade.addDate(-7, null, "D", "DD/MM/YYYY"),
          this.$utilidade.addDate(1, null, "D", "DD/MM/YYYY"),
        ],
      },
    };
  },
  methods: {
    getExtrato() {

      this.loading = true;
      this.arrayRows = []

      this.$root.$request
        .get("Configuracoes/CanalIntegracao", this.formFiltros)
        .then((objResult) => {
          this.loading = false;
          let arrayRows = [];

          if (objResult.status == 200 && objResult.result) {
            objResult.result.forEach((objModel) => {
              let strCor = "green";

              if (objModel.tipoOperacao == "D") {
                strCor = "red";
                objModel.valor = Number(objModel.valor)*-1
              }

              let item = {
                strData:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  this.$utilidade.toDate(objModel.strData, true) +
                  "</span>",
                strDescricao:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.descricao +
                  "</span>",
                strTipo:
                  '<span style="color:' +
                  strCor +
                  '">' +
                  objModel.tipoTransacao +
                  "</span>",
                floatValor:
                  '<span style="color:' +
                  strCor +
                  '">R$ ' +
                  this.$utilidade.floatToMoney(objModel.valor) +
                  "</span>",
                _item: objModel,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Informações Gerais</span>

        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNumeroBanco"
            label="Banco"
            name="strNumeroBanco"
            item-text="strNome"
            item-value="strNumeroBanco"
            route="System/Banco"
            :filters="{
              strBusca: this.formData.strNumeroBanco,
              intRegistroPagina:100
            }"
            :initializeItems="formData.strNumeroBanco ? true : false"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.intNumeroAgencia"
            name="intNumeroAgencia"
            label="Numero da Agencia"
            type="number" 
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="formData.intNumeroConta"
            name="intNumeroAgencia"
            label="Numero da Conta"
            type="number" 
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
            v-model="formData.intNumeroDigitoConta"
            name="intNumeroDigitoConta"
            label="Dígito"
            type="number" 
            rules="required"
          />

          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTipoContaId"
            name="intTipoContaId"
            label="Tipo de Conta"
            tipo="1439"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNomeTitular"
            name="strNomeTitular"
            label="Nome do Titular"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strDocumentoTitular"
            name="strDocumentoTitular"
            label="Documento do Titular"
            rules="required"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strDescricao"
            name="strDescricao"
            label="Observações"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
            v-model="formData.boolPadrao"
            name="boolPadrao"
            label="Definir essa conta como padrão?"
            info="Ao definir como padrão, essa conta será selecionada automaticamente nos lançamentos de cobrança."
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Conta ativo?"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputSwitch,
  EdInputTipo,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputTipo,
    EdInputAutoComplete,
  },

  mounted() {},
  created() {},
  data() {
    return {
      arrayTipoAutenticacao: [
        { intId: "tls", strNome: "TLS" },
        //{ intId: "STARTTLS", strNome: "STARTTLS" },
        { intId: "ssl", strNome: "SSL" },
      ],
    };
  },
  watch: {},
  methods: {},
};
</script>

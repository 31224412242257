<template>
  <div>
    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <v-tabs v-model="strTab" centered icons-and-text>
        <v-tab href="#tab-cadastro">
          Cadastro Geral
          <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
        </v-tab>

        <v-tab href="#tab-extrato" v-if="formData.intId && formData.strNumeroBanco == '77'">
          Consultar Extrato no Banco
          <i :class="$utilidade.getIcone('arquivo') + ' fa-2x'"></i>
        </v-tab>

        <v-tab href="#tab-boleto" v-if="formData.intId && formData.strNumeroBanco == '77'">
          Consultar Boletos
          <i :class="$utilidade.getIcone('pdf') + ' fa-2x'"></i>
        </v-tab>

      </v-tabs>
      <cadastro-geral :formData="formData" :key="'cadastro-' + $root.$session.versao" v-if="strTab == 'tab-cadastro'" />
      <extrato :formData="formData" :key="'extrato-' + $root.$session.versao" v-if="strTab == 'tab-extrato'" />
      <boleto :formData="formData" :key="'boleto-' + $root.$session.versao" v-if="strTab == 'tab-boleto'" />
    </ed-form>
  </div>
</template>

<script>
import { EdForm, EdButton } from "@/components/common/form";

import CadastroGeral from "./partials/geral.vue";
import Extrato from "./partials/extrato.vue";
import Boleto from "./partials/boleto.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    intContaEmailId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    EdButton,
    Extrato,
    Boleto
  },
  mounted() {
    this.getConta();
  },
  created() {},
  data() {
    return {
      strTab:'tab-cadastro',
      formData: {
        intId: 0,
        intTipoContaId: 1440,
        strNumeroBanco: null,
        intNumeroAgencia: null,
        intNumeroConta: null,
        intNumeroDigitoConta:null,
        strNomeTitular: null,
        strDocumentoTitular: null,
        strDescricao: null,
        boolAtivo: 1,
        boolPadrao:0,
      },
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {
  },
  methods: {
    getConta() {
      if (!this.intContaEmailId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Configuracoes/ContaBancaria", { intId: this.intContaEmailId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});
          }
        });
    },


    salvar() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .post("Configuracoes/ContaBancaria", this.formData)
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            if (this.$route.name != "configuracoes.contaBancaria.edit") {
              this.$router.push({
                name: "configuracoes.contaBancaria.edit",
                params: { intId: objResponse.result.intId },
              });
            } else {
              this.getConta();
            }
          }
        });
    },
  },
};
</script>
